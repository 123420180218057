@import '../../variables';
@import '../../mixins';

.live-collaboration {
  @include media-breakpoint('xs') {
    padding-top: 300px;
  }

  @include media-breakpoint('lg') {
    padding-top: 200px;
  }

  &__title {
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.5rem;
    color: $core-black;
    margin: 0 0 1rem 0;

    @include media-breakpoint('lg') {
      font-size: 2.5rem;
      line-height: 3.5rem;
      margin-bottom: 2rem;
      letter-spacing: -0.0125rem;
    }

    @include media-breakpoint('xl') {
      font-size: 3rem;
    }
  }

  &__text {
    font-family: var(--font-second);
    font-size: 1rem;
    font-weight: 300;
    line-height: 2rem;
    color: $core-black;
    margin: 0;
    letter-spacing: 0.0125rem;

    @include media-breakpoint('lg') {
      max-width: 24.125rem;
      color: $black;
    }

    @include media-breakpoint('xl') {
      max-width: 29.3125rem;
    }

    &--1 {
      margin-bottom: 2rem;
    }
  }
}
