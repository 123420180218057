@import '../variables';
@import '../mixins';

.text-input {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 1.3125rem;
  flex: 0 0 100%;

  &:nth-child(1) {
    flex: 0 0 100%;
  }

  &:nth-child(2) {
    margin-top: 1.875rem;
    flex: 0 0 100%;
  }

  &:nth-child(3) {
    margin-top: 1.875rem;
    flex: 0 0 100%;
  }

  @include media-breakpoint('sm') {
    flex: 0 0 100%;
  }

  &__line {
    width: 100%;
    height: 0.0625rem;
    background: $grey;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__input {
    position: relative;
    background: none;
    border: none;
    line-height: 2rem;
    padding: 0;
    flex: 1;
    color: $core-white;
    border-radius: 0;

    &:focus {
      outline: none;

      ~ .text-input__label {
        top: -0.875rem;
        font-size: 0.75rem;
        line-height: 0.875rem;
      }

      ~ .text-input__line {
        background: linear-gradient(22.45deg, #0e4ed7 3.28%, #f94743 102.02%);
      }
    }

    &--filled {
      ~ .text-input__label {
        top: -0.875rem;
        font-size: 0.75rem;
        line-height: 0.875rem;
      }
    }
  }

  &__input:-webkit-autofill {
    position: relative;
    background: none;
    border: none;
    border-bottom: 0.0625rem solid $grey;
    line-height: 2rem;
    padding: 0;
    flex: 1;
    color: $core-white;
    border-radius: 0;

    &:focus {
      outline: none;

      ~ .text-input__label {
        top: -0.875rem;
        font-size: 0.75rem;
        line-height: 0.875rem;
      }

      ~ .text-input__line {
        background: linear-gradient(22.45deg, #0e4ed7 3.28%, #f94743 102.02%);
      }
    }

    &--filled {
      ~ .text-input__label {
        top: -0.875rem;
        font-size: 0.75rem;
        line-height: 0.875rem;
      }
    }
  }

  &__input {
    &--error {
      // border-bottom-color: $accent;

      ~ .text-input__line {
        background: $accent;
      }
    }
  }

  &__label {
    font-size: 1rem;
    line-height: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    flex: 0 0 100%;
    color: $grey;
    transition: top 200ms ease-in-out, font-size 200ms ease-in-out,
      line-height 200ms ease-in-out;
    cursor: text;
  }
}
