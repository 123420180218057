@import '../variables';
@import '../mixins';

.contact-form-result {
  background-color: $core-black;
  width: 100%;
  height: 50vh;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: -1;

  &-enter {
    opacity: 0;
    z-index: -1;

    &-active {
      transition: opacity 300ms ease-in-out;
      opacity: 1;
      z-index: 1;
    }

    &-done {
      transform: translateX(-1rem);
      width: 100vw;
      opacity: 1;
      z-index: 1;

      @include media-breakpoint('sm') {
        transform: none;
        width: 100%;
      }
    }
  }

  &-exit {
    opacity: 1;
    z-index: 1;

    &-active {
      transition: opacity 300ms ease-in-out;
      opacity: 0;
      z-index: 1;
    }

    &-done {
      opacity: 0;
      z-index: -1;
    }
  }

  &__icon {
    height: 3.25rem;
    width: 100%;
    overflow: visible;
    margin: 0 0 2.5rem 0;
    position: relative;

    @include media-breakpoint('md') {
      margin-bottom: 3rem;
    }
  }
}
