@import '../variables';

.file-input {
  &__input {
    display: none;
  }

  &__label {
    font-family: var(--font-second);
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 2rem;
    border: none;
    background: linear-gradient(67.33deg, $primary -19.99%, $accent 98.54%);
    background-clip: text;
    color: rgba($grey, 1);
    transition: color 200ms ease-in-out;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    @mixin pseudo-element-default-content() {
      content: '';
      height: 0.0625rem;
      width: 100%;
      position: absolute;
      bottom: 0.25rem;
      left: 0;
    }

    &:after {
      background: linear-gradient(67.33deg, $primary -19.99%, $accent 98.54%);
      transition: opacity 200ms ease-in-out;
      opacity: 0;

      @include pseudo-element-default-content();
    }

    &:before {
      background: $core-white;

      @include pseudo-element-default-content();
    }

    &:hover {
      color: rgba($grey, 0);

      &:after {
        opacity: 1;
      }
    }
  }
}
