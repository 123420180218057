@import '../variables';
@import '../mixins';

.carousel .slider.animated {
  transition: all 0.15s cubic-bezier(0.4, 1, 1, 1);
}

.carousel-root {
  .carousel-slider {
    overflow: visible !important;
    user-select: none;

    .control-dots {
      display: flex;
      flex-direction: row;
      justify-content: center;
      list-style: none;
      bottom: -2.125rem;
      margin: 0;
    }
  }
}

.carousel .slide {
  @include media-breakpoint('md') {
    padding: 0 0.625rem 0 0 !important;
  }
}