@import '../variables';
@import '../mixins';

.checkbox {
  display: flex;
  align-items: center;
  position: relative;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    left: 0;
  }

  input:checked ~ &__check-mark {
    background: linear-gradient(22.45deg, #0e4ed7 3.28%, #f94743 102.02%);
  }

  &__input-container {
    position: relative;
  }

  &__check-mark {
    min-height: 1.5rem;
    min-width: 1.5rem;
    border: 0.063rem solid $core-white;
    border-radius: 0.25rem;
    cursor: pointer;
    position: relative;

    &:after {
      position: absolute;
      display: none;
      left: 0.5rem;
      top: 0;
      content: '';
      width: 0.5rem;
      height: 1.063rem;
      border: solid white;
      border-width: 0 0.063rem 0.063rem 0;
      transform: rotate(45deg);
    }

    input:checked ~ &:after {
      display: block;
    }
  }

  &--error {
    .checkbox {
      &__check-mark {
        border: 0.0625rem solid $accent;
      }
    }
  }

  &__label {
    margin-left: 0.75rem;
    font-family: var(--font-second);
    font-style: normal;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.688rem;
    color: $core-white;
  }
}
