@import '../variables';
@import '../mixins';

.article-summary {
  &-meta {
    display: flex;
    flex-direction: row;

    &__image {
      display: flex;
      align-items: center;
    }

    &__author {
      display: flex;
      flex-direction: row;
      margin-top: 1.5rem;

      @include media-breakpoint('md') {
        padding-left: 3rem;
      }

      @include media-breakpoint('xl') {
        padding-left: 12.5rem;
      }
    }

    .divider {
      margin-left: 0.75rem;
      margin-right: 0.75rem;
    }

    &__name {
      font-family: var(--font-base);
      font-size: 1rem;
      line-height: 2rem;
      letter-spacing: 0.013rem;
      color: $core-black;
    }

    &__details {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;
      color: #667070;

      .divider {
        display: block;
      }
    }

    &__info {
      padding-left: 0.5rem;
      display: flex;
      flex-direction: column;
      color: #667070;
      font-size: 0.75rem;
      line-height: 0.875rem;
      font-family: var(--font-second);
    }

    &__tag {
      color: #667070;
      font-size: 0.75rem;
      line-height: 0.85rem;

      &:first-child {
        display: initial;
      }
    }

    &__tag + &__tag {
      margin-left: 0.75rem;
    }
  }

  &-header {
    &__title {
      line-height: 2rem;
      font-weight: 400;
      margin: 0 0 0.5rem 0;
      font-family: var(--font-base);
      font-size: 1.5rem;
      background: linear-gradient(22.45deg, $primary 3.28%, $accent 102.02%);
      background-clip: text;
      color: $black;
      text-decoration: none;
      transition: color 200ms ease-in-out;
      display: block;

      @include media-breakpoint('md') {
        margin: 0 0 1rem 0;
      }

      @include media-breakpoint('lg') {
        font-size: 2rem;
        line-height: 2.5rem;
      }

      &:hover {
        color: rgba($black, 0);
      }

      &:focus {
        outline: none;
      }
    }

    @include media-breakpoint('md') {
      padding: 0;
    }

    @include media-breakpoint('xl') {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  &__image {
    margin-top: 1.5rem;
    cursor: pointer;
    border-radius: 0.5rem;

    a > img {
      transition: box-shadow 200ms ease-in-out;
      border-radius: 0.5rem;

      &:hover {
        box-shadow: 0.5rem 0.5rem 1.5rem 0 #1700594a;
      }
    }
  }
}
