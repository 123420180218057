@import '../variables';
@import '../mixins';

.our-values {
  background-color: $core-black;
  padding-top: 4rem;
  overflow: hidden;

  @include media-breakpoint('sm') {
    padding-top: 5rem;
  }

  @include media-breakpoint('xl') {
    padding-top: 7.5rem;
    font-size: 3.5rem;
  }

  &__slider {
    margin: 0 0 2.5rem 0;
    max-height: 5rem;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    display: flex;

    @include media-breakpoint('xl') {
      margin-bottom: 7.5rem;
      overflow-x: hidden;
    }

    &:hover {
      .our-values__track {
        animation-play-state: paused;
      }
    }
  }

  &__track {
    width: auto;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 120s linear infinite;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @keyframes marquee {
      from {
        transform: translateX(0);
      }

      to {
        transform: translateX(-100%);
      }
    }
  }

  &__cursor {
    position: fixed;
    top: -1rem;
    left: -1rem;
    border-radius: 50%;
    pointer-events: none;
    z-index: 0;

    div {
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      background: linear-gradient(67.33deg, $primary -19.99%, $accent 98.54%);
      cursor: default;
      //transition: transform .5s ease, opacity .35s ease;
    }

    &--is-active {
      div {
        transform: scale(2.5);
        opacity: 1;
      }
    }

    &--hide {
      display: none;
    }
  }

  &__value,
  &__value2 {
    line-height: 5rem;
    font-size: 1.5rem;
    color: $dark-grey;
    transition: color 300ms ease-in-out;
    display: inline-block;
    text-align: center;
    position: relative;
    flex: 0 0 auto;
    margin: 0 5rem;
    cursor: pointer;

    &--selected {
      color: $core-white;

      &:after {
        content: '';
        background: linear-gradient(67.33deg, $primary -19.99%, $accent 98.54%);
        position: absolute;
        height: 5rem;
        width: 5rem;
        border-radius: 5rem;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        opacity: 1;
        transition: opacity 300ms ease-in-out;
      }
    }

    @include media-breakpoint('sm') {
      margin: 0 3rem;
    }

    @include media-breakpoint('xl') {
      margin: 0 5rem;
    }

    &:hover {
      color: $core-white;
    }
  }

  &__descriptions {
    @include default-container-size();

    min-height: 10rem;
    padding-bottom: 4rem;

    @include media-breakpoint('sm') {
      min-height: 9rem;
    }

    @include media-breakpoint('xl') {
      padding-bottom: 7.5rem;
    }
  }

  &__description {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    visibility: hidden;
    position: absolute;
    min-height: 12rem;
    width: calc(100% - 2rem);

    @include media-breakpoint('sm') {
      width: calc(100% - 7rem);
      max-width: 42rem;
    }

    @include media-breakpoint('xl') {
      min-height: 9rem;
      width: auto;
    }

    &--active {
      opacity: 1;
      visibility: visible;
    }
  }
}

@keyframes change-value {
  from {
    left: 0;
    top: 50%;
  }
  to {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
