@import '../variables';
@import '../mixins';

.hero {
  &__lottie {
    flex: 1;
    height: auto;
    display: none;

    @include media-breakpoint('md') {
      display: inline-block;
      margin: 0 0 0.85rem -0.35rem;
    }

    @include media-breakpoint('xl') {
      margin: 0 0 1.38rem -0.7rem;
    }
  }
}
