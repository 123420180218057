@import "../variables";
@import "../mixins";

.newsletter {
  &__input {
    color: $black;
  }

  .text-input {
    margin-bottom: 2rem;

    &:nth-child(2) {
      margin-left: 0;
    }

    @include media-breakpoint('sm') {
      &:nth-child(2) {
        margin-left: .5rem;
      }
    }

    &__input, &__input:-webkit-autofill {
      border-bottom: 0.0625rem solid $black;
    }

    &__label {
      color: $black;
    }

    @include media-breakpoint('md') {
      max-width: 20.4375rem;
    }

    @include media-breakpoint('xl') {
      max-width: 20.4375rem;
      margin-bottom: 2.5rem;
    }
  }

  &__checkbox {
    margin-bottom: 2.5rem;

    .checkbox__input-container {
      margin-right: 0.75rem;
      align-self: flex-start;
    }

    .checkbox__check-mark {
      display: inline-block;
      border: 0.063rem solid #afafaf;
    }

    .checkbox__label {
      margin: 0 0.5rem 0 0;
      color: $black;
      font-weight: 300;
      letter-spacing: 0.0125rem;
      line-height: 1.5rem;
      font-size: 1rem;
      max-width: 16.1875rem;

      @include media-breakpoint('md') {
        max-width: 37.5rem;
      }

      @include media-breakpoint('lg') {
        max-width: 47.5rem;
      }

      @include media-breakpoint('xl') {
        max-width: 24.625rem;
      }
    }

    &-link {
      background: linear-gradient(67.33deg, $primary -19.99%, $accent 98.54%);
      background-clip: text;
      color: $black;
      font-weight: 300;
      letter-spacing: 0.0125rem;
      line-height: 1.5rem;
      font-size: 1rem;
      transition: color 200ms ease-in-out;
      position: relative;
      text-decoration: none;

      @mixin pseudo-element-default-content() {
        content: "";
        height: 0.0625rem;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &:after {
        background: linear-gradient(67.33deg, $primary -19.99%, $accent 98.54%);
        transition: opacity 200ms ease-in-out;
        opacity: 0;

        @include pseudo-element-default-content();
      }

      &:before {
        background: $black;

        @include pseudo-element-default-content();
      }

      &:hover {
        color: rgba($black, 0);

        &:after {
          opacity: 1;
        }
      }

      &:focus {
        color: rgba($black, 0);
        outline: none;

        &:after {
          opacity: 1;
        }
      }


    }
  }
}

.checkbox--error {
  .checkbox {
    &__check-mark {
      border: 0.0625rem solid $accent;
    }
  }
}

.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 999 !important;
    background-color: rgba($core-black, 0.65) !important;
    opacity: 0;
    transition: opacity 500ms ease-in-out;

    .ReactModal__Content {
      border: none !important;
      padding: 0 !important;
      border-radius: 0.5rem;
      width: 21.4375rem;
      height: 25.625rem;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;


      @include media-breakpoint('md') {
        width: 42rem;
        overflow: hidden !important;
        height: auto;
      }

      @include media-breakpoint('lg') {
        width: 51.5rem;
      }

      @include media-breakpoint('xl') {
        width: 52.0625rem;
      }
    }
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
}

.ReactModal__Body--open {
  overflow-y: hidden;
}