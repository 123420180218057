@import '../variables';
@import '../mixins';

.case-study {
  .testimonial__quote {
    position: relative;
    overflow: hidden;

    .testimonial__paragraph {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 300;
      opacity: 0;
      max-height: 0;
      transition: max-height 500ms ease-in-out, opacity 600ms ease-in-out;

      &:first-child {
        opacity: 1;
        max-height: 62.5rem;
        height: auto;
      }

      @include media-breakpoint('md') {
        font-size: 1.125rem;
        line-height: 2rem;
      }

      @include media-breakpoint('xl') {
        opacity: 1;
        max-height: 62.5rem;
        height: auto;
      }
    }

    &--show {
      .testimonial__paragraph {
        opacity: 1;
        max-height: 62.5rem;
        height: auto;
      }
    }
  }

  &__diagram {
    &-screen-mobile {
      margin-top: 4rem;

      @include media-breakpoint('xs') {
        display: block;
      }

      @include media-breakpoint('md') {
        display: none;
      }

      .carousel .slider.animated {
        transition: all 0.15s cubic-bezier(0.4, 1, 1, 1);
      }

      .carousel-root {
        .carousel-slider {
          overflow: visible;

          .control-dots {
            display: flex;
            flex-direction: row;
            justify-content: center;
            list-style: none;
            bottom: -2.125rem;
            margin: 0;
          }
        }
      }
    }

    &-image-mobile {
      width: 100%;
      max-width: 30.3125rem;
      max-height: 30rem;
      object-fit: contain;
      object-position: center;
      image-resolution: 3000dpi;
    }
  }
}
