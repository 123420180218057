@import 'variables';
@import 'mixins';

html,
body {
  scroll-behavior: smooth;
  font-family: var(--font-base);
  background-color: $core-black;
  //highlight-color: $core-white;
  font-size: 16px;
  hyphens: none;
  outline-style: solid;
  overflow-x: hidden;

  ::selection {
    background: $accent;
    color: $core-black;
  }

  img {
    &::selection {
      background: transparent;
    }
  }

  a,
  button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
  }
}

main {
  margin-top: 3.75rem;

  @include media-breakpoint('xl') {
    margin-top: 5rem;
  }
}
