.article-meta {
  display: flex;
  flex-direction: row;

  &__author {
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;

    @include media-breakpoint('md') {
      padding-left: 3rem;
    }

    @include media-breakpoint('xl') {
      padding-left: 12.5rem;
    }
  }

  .divider {
    margin: 0 0.75rem;
  }

  &__details {
    display: inline-flex;
    color: #667070;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__image {
    padding-right: 1rem;
  }

  &__tag {
    color: #667070;
    font-size: 0.75rem;
    line-height: 0.85rem;
  }

  &__tag + &__tag {
    margin-left: 0.75rem;
  }
}
