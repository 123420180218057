@import '../../variables';
@import '../../mixins';

.our-design-process {
  position: relative;

  & svg {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    grid-column: 1;
    grid-row: 1;
    justify-self: center;

    @include media-breakpoint('md') {
      grid-row: 2;
      grid-column: 1 /7;
    }
  }
}
