@import '../variables';
@import '../mixins';

.contact-form {
  &__agreement {
    margin-bottom: 1.5rem;

    &__label-link {
      text-decoration: none;
      position: relative;
      background: linear-gradient(67.33deg, $primary -19.99%, $accent 98.54%);
      background-clip: text;
      color: rgba($core-white, 1);
      transition: color 200ms ease-in-out;
      font-style: normal;

      &:after {
        background: linear-gradient(67.33deg, $primary -19.99%, $accent 98.54%);
        transition: opacity 200ms ease-in-out;
        opacity: 0;

        @include pseudo-element-default-content();
        height: 0.063rem;
        bottom: 0.25rem;
      }

      &:before {
        background: $core-white;

        @include pseudo-element-default-content();
        height: 0.063rem;
        bottom: 0.25rem;
      }

      &:hover {
        color: rgba($core-white, 0);
        &:after {
          opacity: 1;
        }
      }

      &:focus {
        color: rgba($core-white, 0);
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__error {
    color: $accent;
    width: 100%;
    font-size: 0.75rem;
    position: absolute;
    margin: 0;
    bottom: -1.3125rem;

    &--checkboxes {
      bottom: -0.8125rem;

      @include media-breakpoint('xl') {
        bottom: -0.3125rem;
      }
    }
  }
}

/*! purgecss end ignore */
