@import '../variables';

.fieldset {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  border: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: 0;
  margin-right: 0;

  &__title {
    font-size: 1.25rem;
    line-height: 2rem;
    display: block;
    color: $core-white;
    margin-bottom: 1rem;
  }
}
