@import '../variables';
@import '../mixins';

.testimonial {
  &__quote {
    display: flex;
    flex-direction: column;
    background-color: $core-black;
    border-radius: 0.5rem;
    padding: 2.5rem 2rem;
    width: 100%;

    @include media-breakpoint('md') {
      width: auto;
    }
  }
}
