@import '../variables';
@import '../mixins';

.product-design {
  &__our-design-process {
    background-color: $core-black;
    position: relative;

    &-wrapper {
      @include default-container-size();
      padding-top: 4rem;
      padding-bottom: 7.5rem;

      @include media-breakpoint('lg') {
        padding-top: 5.5rem;
        padding-bottom: 5rem;
      }

      @include media-breakpoint('lg') {
        padding-top: 7.5rem;
        padding-bottom: 11.25rem;
      }
    }

    &-collaboration-timeline {
      background: #ffffff;
      box-shadow: 8px 8px 24px rgba(23, 0, 89, 0.29);
      border-radius: 8px;
      position: relative;
      padding: 32px;

      &-title {
        font: normal normal 2rem/2.5rem var(--font-base), sans-serif;
        color: #000000;
        margin: 0;

        @include media-breakpoint('xs') {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }

      width: min(80vw, 1170px);
      margin: 0 auto;

      @include media-breakpoint('xs') {
        bottom: -300px;
        margin: -300px auto 0;
        padding-bottom: 40px;
      }

      @include media-breakpoint('md') {
        bottom: -300px;
        margin: -300px auto 0;
        padding-bottom: 64px;
      }

      @include media-breakpoint('lg') {
        margin: -200px auto 0;
        bottom: -200px;
        padding-bottom: 80px;
      }

      &-video {
        width: 100%;
        height: 500px;

        & video {
          width: 100%;
          @include media-breakpoint('xs') {
            height: 500px;
          }
          @include media-breakpoint('md') {
            height: 500px;
          }
        }
      }
    }
  }

  &__live-collaboration {
    background-color: $light-grey;

    &-wrapper {
      display: flex;
      flex-direction: column;
      @include default-container-size();
      padding-top: 7.5rem;

      @include media-breakpoint('lg') {
        flex-direction: row;
        padding-top: 5rem;
      }

      @include media-breakpoint('lg') {
        padding-top: 5rem;
      }

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint('md') {
          min-width: 29rem;
        }
      }
    }
  }

  &__testimonial {
    margin-bottom: 0 !important;

    .testimonial__paragraph {
      max-width: 60.625rem;
    }

    .short-testimonial__author {
      justify-content: flex-end !important;
      margin-top: 1rem !important;
    }

    .author__details {
      text-align: right !important;

      .author__name {
        font-size: 1.5rem !important;
        font-weight: 400 !important;
        line-height: 2rem !important;
      }

      .author__position {
        font-family: var(--font-second) !important;
        font-size: 1rem !important;
        font-weight: 300 !important;
        line-height: 2rem !important;
        letter-spacing: 0.0125rem !important;
        color: $black !important;
      }

      @include media-breakpoint('md') {
        margin-right: 4.125rem;
      }

      @include media-breakpoint('xl') {
        margin-right: 6.125rem;
      }
    }
  }
}
