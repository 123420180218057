@import '../variables';
@import '../mixins';

.service {
  color: $core-white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
  height: 100%;
  width: auto;
  margin: 0 0 6rem 0;

  &:last-child {
    margin-bottom: 4rem;
  }

  @include media-breakpoint('md') {
    margin: 1.5rem 0 6.5rem 0;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 3rem;
  }

  @include media-breakpoint('lg') {
    padding: 0 6.5rem;
  }

  @include media-breakpoint('xl') {
    padding: 0;
    width: 100%;
    margin: 0 0 13rem 0;
    &:first-child {
      margin-top: 5.5rem;
    }

    &:last-child {
      margin-bottom: 7.5rem;
    }
  }

  &__stage--hover {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  &__stage--hover-empty {
    flex-direction: column;
  }

  &__stage-arrow {
    margin-left: 1rem;
  }

  &__stage-arrow-path {
    transition: stroke 200ms ease-in-out;
    stroke: $core-white;
  }

  &__stage-empty {
    border-radius: 0.5rem;
    background-color: $darker-grey;
    padding: 0 0.5rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;
    letter-spacing: 0.013rem;
    text-align: left;

    margin-left: 0;
    position: absolute;
    top: 2.5rem;

    @include media-breakpoint('sm') {
      margin-left: 0.75rem;
      position: relative;
      top: 0;
    }
  }

  &__content {
    @include media-breakpoint('md') {
      max-width: 21.625rem;
    }

    @include media-breakpoint('lg') {
      max-width: 23.063rem;
    }

    h3 {
      font-family: var(--font-second);
      font-size: 1.125rem;
      line-height: 2rem;
      margin: 1.5rem 0 0.5rem 0;

      @include media-breakpoint('xl') {
        margin: 2rem 0 0.5rem 0;
      }
    }
  }

  &__right-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2.5rem;

    @include media-breakpoint('md') {
      width: 16.813rem;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 0;
    }

    @include media-breakpoint('lg') {
      width: 23.125rem;
      justify-content: flex-end;
    }
  }

  &__image {
    margin: -1rem 0 2rem 0;
    width: 15.5rem;
    &.mobile {
      .service__lottie {
        width: auto;
        height: auto;
      }
    }

    &.desktop {
      display: none;
    }

    @include media-breakpoint('md') {
      margin: 0 0 4rem 0;

      &.desktop {
        display: block;
      }

      &.mobile {
        display: none;
      }
    }

    @include media-breakpoint('lg') {
      width: 18.75rem;
    }

    @include media-breakpoint('xl') {
      margin: 0 0 4rem 0;

      &.desktop {
        display: block;
      }

      &.mobile {
        display: none;
      }
    }
  }
}
