@import '../variables';
@import '../mixins';

.our-develop-process {
  position: relative;

  &__stages {
    display: grid;
    justify-items: center;
    word-break: break-word;

    @include media-breakpoint('xs') {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, 1fr);
      grid-column: 1;
      grid-row: 1;
    }

    @include media-breakpoint('md') {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr;
      grid-row: 1;

      &:last-of-type {
        grid-row: 3;
      }
    }
    @include media-breakpoint('lg') {
      grid-template-columns: repeat(6, 10.625rem);
    }

    @include media-breakpoint('xl') {
      grid-template-columns: repeat(6, 11.8rem);
    }

    .top {
      margin-bottom: -2rem;

      flex-direction: column-reverse;
      @include media-breakpoint('xs') {
        text-align: right;
        justify-content: center;
        align-items: flex-end;

      }

      margin-left: 1.875rem;

      &:before {
        order: 1;
      }

      @include media-breakpoint('md') {
        flex-direction: column;
        margin-bottom: -1.875rem;
        margin-right: 0;
        margin-left: 0;

        align-items: center;
        justify-content: flex-end;
        text-align: center;
      }

      @include media-breakpoint('lg') {
        margin-bottom: -1.875rem;
      }

      @include media-breakpoint('xl') {
        margin-bottom: -3.125rem;
      }
    }
    .bottom {
      margin-top: -2rem;
      flex-direction: column-reverse;
      @include media-breakpoint('xs') {
        align-items: flex-start;
        text-align: left;
        justify-content: center;
      }

      margin-right: 1.875rem;

      &:before {
        order: 1;
      }

      @include media-breakpoint('md') {
        flex-direction: column-reverse;
        margin-top: -1.875rem;
        margin-right: 0;
        margin-left: 0;
        align-items: center;
        justify-content: flex-end;
        text-align: center;
      }

      @include media-breakpoint('lg') {
        margin-top: -1.875rem;
      }

      @include media-breakpoint('xl') {
        margin-top: -1.875rem;
      }

    }

    &-stage {
      display: flex;
      flex-direction: column;

      @include media-breakpoint('md') {
        &:before {
          position: absolute;
          top: 0;
          display: none;
        }
      }

      //upper stages-stage
      &--top-0 {
        position: relative;

        @include media-breakpoint('xs') {
          grid-row: 2;
          grid-column: 1;

          &::before {
            background-repeat: no-repeat;
            background-size: 5rem 5rem;
            content: ' ';
            display: block;
            width: 5rem;
            height: 5rem;
          }
        }
        @include media-breakpoint('md') {
          grid-row: 1;
          grid-column: 2;
          justify-content: space-between;
          &:before {
            display: none;
          }

          &:after {
            position: absolute;
            background-repeat: no-repeat;
            background-size: 5rem 5rem;
            content: ' ';
            display: block;
            width: 5rem;
            height: 5rem;
            bottom: -5rem;
          }
        }

      }

      &--top-1 {
        position: relative;

        @include media-breakpoint('xs') {
          grid-row: 4;
          grid-column: 1;
          &:before {
            background-repeat: no-repeat;
            background-size: 5rem 5rem;
            content: ' ';
            display: block;
            width: 5rem;
            height: 5rem;
            top: 0;
          }
        }
        @include media-breakpoint('md') {
          grid-row: 1;
          grid-column: 4;
          &:before {
            display: none;
          }

          &:after {
            position: absolute;
            background-repeat: no-repeat;
            background-size: 5rem 5rem;
            content: ' ';
            display: block;
            width: 5rem;
            height: 5rem;
            bottom: -5rem;
          }
        }
        justify-content: space-between;

      }

      &--top-2 {
        position: relative;

        @include media-breakpoint('xs') {
          grid-row: 6;
          grid-column: 1;

          &:before {
            background-repeat: no-repeat;
            background-size: 5rem 5rem;
            content: ' ';
            display: block;
            width: 5rem;
            height: 5rem;
            top: 0;
          }
        }
        @include media-breakpoint('md') {
          grid-row: 1;
          grid-column: 6/7;

          &:before {
            display: none;
          }

          &:after {
            position: absolute;
            background-repeat: no-repeat;
            background-size: 5rem 5rem;
            content: ' ';
            display: block;
            width: 5rem;
            height: 5rem;
            bottom: -5rem;
          }
        }
        justify-content: space-between;
      }

      //bottom stages-stage
      &--bottom-0 {
        position: relative;
        &:before {
          background-repeat: no-repeat;
          background-size: 5rem 5rem;
          content: ' ';
          display: block;
          width: 5rem;
          height: 5rem;
        }

        @include media-breakpoint('xs') {
          grid-row: 1;
          grid-column: 1;

          &:before {
            top: 0;
          }
        }
        @include media-breakpoint('md') {
          grid-row: 1;
          grid-column: 1;

          &:before {
            top: -5rem;
          }
        }
      }

      &--bottom-1 {
        position: relative;
        &:before {
          background-repeat: no-repeat;
          background-size: 5rem 5rem;
          content: ' ';
          display: block;
          width: 5rem;
          height: 5rem;
        }

        @include media-breakpoint('xs') {
          grid-row: 3;
          grid-column: 1;
          &:before {
            top: 0;
          }
        }
        @include media-breakpoint('md') {
          grid-row: 1;
          grid-column: 3;
          &:before {
            top: -5rem;
          }
        }
      }

      &--bottom-2 {
        position: relative;
        &:before {
          background-repeat: no-repeat;
          background-size: 5rem 5rem;
          content: ' ';
          display: block;
          width: 5rem;
          height: 5rem;
        }

        @include media-breakpoint('xs') {
          grid-row: 5;
          grid-column: 1;
          &:before {
            top: 0;
          }
        }
        @include media-breakpoint('md') {
          grid-row: 1;
          grid-column: 5;

          &:before {
            top: -5rem;
          }
        }
      }
    }
  }
}
