@import '../variables';
@import '../mixins';

.insights {
  .hero__lottie {
    @include media-breakpoint('md') {
      margin: 0 0 0.98rem -0.4rem;
    }

    @include media-breakpoint('xl') {
      margin: 0 0 1.68rem -0.7rem;
    }
  }
}
