@import '../variables';
@import '../mixins';

.clutch-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 35.5625rem !important;
  background: $core-white;
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 1.5rem;
  text-decoration: none;
  transition: box-shadow 200ms ease-in-out;

  @include media-breakpoint('xl') {
    padding: 2.5rem 2rem;
  }

  .clutch-widget {
    position: relative;
    max-width: 11.5625rem;
    min-height: 1.5rem;
    height: 100%;
    max-height: 2.375rem;
    z-index: 10;
  }

  &__text {
    color: $black;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2rem;
    margin: 0 auto 0 0;

    @include media-breakpoint('xl') {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  &:hover {
    box-shadow: 0.5rem 0.5rem 1.5rem rgba(23, 0, 89, 0.29);
  }
}
