@import '../variables';
@import '../mixins';

.about-us {
  .hero__lottie {
    @include media-breakpoint('md') {
      margin: 0 0 0.9rem -0.41rem;
    }

    @include media-breakpoint('xl') {
      margin: 0 0 1.57rem -0.7rem;
    }
  }
}
