@import '../variables';
@import '../mixins';

.article-header {
  &__title {
    font-size: 2rem !important;
    line-height: 2.5rem !important;
    color: $black !important;
    margin: 0 !important;
    font-weight: 400 !important;
    text-decoration: none !important;
    padding-bottom: 1rem !important;

    @include media-breakpoint('md') {
      font-size: 2.5rem !important;
      line-height: 3rem !important;
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }

    @include media-breakpoint('xl') {
      padding-top: 2rem !important;
      padding-bottom: 1.5rem !important;
    }
  }
}
