@import '../variables';
@import '../mixins';

.top-articles {
  .top-article {
    &__image {
      margin-right: 1.875rem;
      width: 10.625rem;

      a > img {
        border-radius: 0.5rem;
        transition: box-shadow 200ms ease-in-out;
        height: 7.0625rem;

        &:hover {
          box-shadow: 0.5rem 0.5rem 1.5rem 0rem #1700594a;
        }
      }

      a:focus {
        outline: none;
      }
    }
  }
}
