@import '../variables';
@import '../mixins';

.checkbox-input {
  display: inline-flex;
  align-items: flex-start;
  background-color: $grey;
  border-radius: 4rem;
  margin: 0 0.5rem 0.5rem 0;

  @include media-breakpoint('xl') {
    margin: 0 1rem 1rem 0;
  }

  &__input {
    display: none;

    &--checked {
      ~ .checkbox-input__label {
        background: linear-gradient(67.33deg, $primary -19.99%, $accent 98.54%);
        background-size: 100% 100%;
        color: $core-white;
      }
    }
  }

  &__label {
    font-size: 1rem;
    line-height: 2rem;
    color: $core-black;
    cursor: pointer;
    border-radius: 4rem;
    padding: 0.25rem 1rem;
    user-select: none;

    @include box-shadow();
  }
}
