@use "sass:map";

@import 'variables';

$breakpoints: (
  xs: 320px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

@mixin media-breakpoint($breakpoint) {
  @media screen and (min-width: map.get($breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin pseudo-element-default-content() {
  content: '';
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

@mixin box-shadow() {
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    box-shadow: $primary-box-shadow;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4rem;
    z-index: 0;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
}

@mixin default-container-size() {
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 77.125rem;

  @include media-breakpoint('sm') {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  @include media-breakpoint('xl') {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
