@import '../variables';
@import '../mixins';

.article-next {
  &__image {
    display: flex;
    min-width: 7.75rem;
    max-width: 7.75rem;
    min-height: 8rem;
    max-height: 8rem;

    a > img {
      border-radius: 0.9375rem;
      transition: box-shadow 200ms ease-in-out;

      &:hover {
        box-shadow: 0.5rem 0.5rem 1.5rem 0 #1700594a;
      }
    }

    @include media-breakpoint('md') {
      max-width: 16.875rem;
      max-height: 11.875rem;
      align-items: center;

      a {
        height: 100%;

        img {
          height: 100%;
        }
      }
    }

    img {
      border-radius: 0.5rem;
      object-fit: cover;
    }
  }
}

.article-next-meta {
  display: flex;
  flex-direction: row;

  @include media-breakpoint('xl') {
    padding-top: 1rem;
  }

  &__author {
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;

    @include media-breakpoint('md') {
      padding-left: 3rem;
    }

    @include media-breakpoint('xl') {
      padding-left: 12.5rem;
    }
  }

  .divider {
    margin: 0 0.75rem;
  }

  &__image {
    display: none;
    color: $darker-grey;

    @include media-breakpoint('xl') {
      color: $black;
      display: block;
    }
  }

  &__name {
    font-family: var(--font-base);
    font-size: 1rem;
    line-height: 2rem;
    color: $darker-grey;

    @include media-breakpoint('xl') {
      color: $core-black;
      font-size: 1.25rem;
      letter-spacing: 0.0125rem;
    }
  }

  &__details {
    display: none;

    @include media-breakpoint('xl') {
      display: inline-flex;
      color: $darker-grey;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    @include media-breakpoint('xl') {
      padding-left: 0.5rem;
    }
  }

  &__tag {
    color: $darker-grey;
    font-size: 0.75rem;
    line-height: 0.85rem;
  }

  &__tag + &__tag {
    margin-left: 0.75rem;
  }
}
