$primary: #0e4ed7;
$accent: #f94743;

$core-black: #000000;
$core-white: #ffffff;

$black: #212121;
$darker-grey: #667070;
$dark-grey: #6e767d;
$grey: #d8dfe4;
$light-grey: #f4f4f4;

$case-study-table-first: #254c87;
$case-study-table-second: #008e9e;

$primary-box-shadow: 0.5rem 0.5rem 1.5rem rgba(65, 70, 176, 0.86);

$safe-area-top: env(safe-area-inset-top, 0px);
$safe-area-right: env(safe-area-inset-right, 0px);
$safe-area-bottom: env(safe-area-inset-bottom, 0px);
$safe-area-left: env(safe-area-inset-left, 0px);
