@import '../variables';
@import '../mixins';

.article {
  &-header {
    padding-top: 1.5rem;

    @include media-breakpoint('md') {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    @include media-breakpoint('xl') {
      padding-top: 2.5rem;
      padding-left: 12.5rem;
      padding-right: 12.5rem;
    }
  }
}
