@import '../variables';
@import '../mixins';

.parallax {

  width: 100%;
  &__section {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-height: 52rem;

    &:nth-child(even) {
      background: black;
      color: white;

      .parallax__section-container-left-description {
        color: $light-grey;
      }

      .parallax__section-container-right-description {
        color: $light-grey;
      }
    }
    &:nth-child(odd) {
      color: black;

      .parallax__section-container-right-subtitle-info {
        color: $darker-grey
      }
    }

    &-invert {
      .back-button {
        color: $core-white;
        background-color: transparent;
        path {
          stroke: $core-white
        }
        &:before {
          opacity: 0
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }
      }

      &:nth-child(even) {
        background: $core-white;
        color: $core-black;

        .parallax__section-container-left-description {
          color: $core-black;
        }

        .parallax__section-container-right-description {
          color: $core-black;
        }
      }
      &:nth-child(odd) {
        background: $core-black;
        color: $darker-grey;

        .parallax__section-container-left-description {
          color: $light-grey
        }

        .parallax__section-container-left-title {
          color: $core-white;
        }

        .parallax__section-container-right-description {
          color: $light-grey;
        }
      }

      .parallax__section-container-image-info-title {
        color: white;
      }
    }

    &-container {
      clip-path: inset(0 0 0 0);
      -webkit-clip-path: inset(0 0 0 0);
      display: grid;
      gap: 50px;
      margin: 0 auto;
      width: 100%;
      max-width: 1234px;
      height: 52rem;
      padding: 40px 0;

      @include media-breakpoint('xs') {
        gap: 16px;
        grid-template-columns: minmax(0, 1fr);
        padding: 32px 16px;
      }

      @media screen and (min-width: 1168px)  {
        gap: 50px;
        grid-template-columns:
          23.5rem
          auto
          21.5rem;
        padding: 40px;
        width: 100%
      }

      &-left {
        display: flex;
        flex-direction: column;
        text-align: left;

        &-title {
          font: normal normal 2.5rem/3.5rem var(--font-base), sans-serif;
          letter-spacing: -0.013rem;
          margin: 0;
        }

        &-subtitle-info {
          font: 400 normal 1rem/2rem var(--font-second), sans-serif;
          letter-spacing: 0.013rem;
        }

        &-description {
          font: 300 normal 1rem/1.5rem var(--font-second), sans-serif;
          letter-spacing: 0.013rem;
          margin: 0.5rem 0;
        }
      }
      &-right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: right;
        font: 300 normal 1rem/1.5rem var(--font-second), sans-serif;

        &-subtitle-info {
          font: 400 normal 1.5rem/2rem var(--font-second), sans-serif;
          letter-spacing: 0.013rem;

          &--help-you-with {
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 300;
          }
        }

        &-subtitle {
          font: normal normal 1.5rem/2rem var(--font-base), sans-serif;
          margin: 8px 0;
        }

        &-description {
          letter-spacing: 0.013rem;
          margin: 0;
        }
      }
      &-image {
        display: flex;
        justify-content: center;

        @include media-breakpoint('xs') {
          align-items: center;
        }

        @media screen and (min-width: 1168px) {
          align-items: flex-start;
        }

        &-info {
          text-align: center;

          &-text {
            font: normal normal 1.25rem/2rem var(--font-base), sans-serif;
            background: linear-gradient(
                            22.45deg,
                            #0e4ed7 3.28%,
                            #f94743 102.02%
            );
            background-clip: content-box;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @include media-breakpoint('md') {
              font: normal normal 2rem/2.5rem var(--font-base), sans-serif;
            }
          }
          &-title {
            color: $black;
            font-size: 2rem;
            line-height: 2.5rem;
            letter-spacing: 0.2px;
            margin: 5px 0;

            @include media-breakpoint('md') {
              font-size: 4rem;
              line-height: 5rem;
            }
          }
        }

        & img {
          position: relative;
          top: 0;
          width: var(--imgWidth);

          @media screen and (min-width: 1168px) {
            position: fixed;
            top: calc(12.5vh + 155px);
            width: var(--imgWidth);
          }
        }
      }
    }
  }
  .main-section {
    .parallax__section-container-left {
      justify-content: space-between;
    }
    @include media-breakpoint('xs') {
      .parallax__section-container-image {
        flex-direction: column;
      }
      .product-design__introduction-subtitle {
        font-family: var(--font-second);
        font-size: 20px;
        font-weight: 300;
        line-height: 32px;
        letter-spacing: 0.20000000298023224px;
        text-align: center;
        color: #667070;
      }

      .product-design__introduction-subtitle,
      .parallax__section-container-right-description {
        text-align: center;
        color: $darker-grey;
      }
      .parallax__section-container-left-description,
      .parallax__section-container-right-description {
        display: none;

      }
      .parallax__section-container-right-subtitle-info {
        text-align: left;
        font-family: var(--font-base);
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0;
        color: $core-white;
      }
    }
    @media screen and (min-width: 1168px) {
      .parallax__section-container-image {
        flex-direction: row;
      }

      .product-design__introduction-subtitle,
      .parallax__section-container-left-description {
        font-size: 1.5rem;
        line-height: 2rem;
        display: inherit;
        text-align: left;

        &--font-questrial {
          font-family: var(--font-base);
        }
      }

      .parallax__section-container-right {
        font-size: 1.25rem;
        line-height: 2rem;
        display: inherit;
      }

      .parallax__section-container-right-description {
        display: block;
        text-align: left;
      }

      .parallax__section-container-right-subtitle-info {
        display: none;
      }
    }
  }
}

:root {
  @include media-breakpoint('xs') {
    --imgWidth: 335px;
    --parallaxContainer: auto;
  }
  @include media-breakpoint('md') {
    --imgWidth: 400px;
    --parallaxContainer: calc(50vh - 2.5rem);
  }
  @include media-breakpoint('lg') {
    --imgWidth: 450px;
    --parallaxContainer: calc(50vh - 2.5rem);
  }
  @include media-breakpoint('xxl') {
    --imgWidth: 536px;
  }
}
