@import '../variables';
@import '../mixins';
@import '../functions';

.person {
  position: relative;
  display: flex;
  border-radius: 0.5rem;
  padding: 1.75rem 1.875rem;
  background-color: $grey;
  flex-wrap: wrap;
  max-width: calc(50% - 4.75rem);
  width: 100%;
  overflow: hidden;

  @include media-breakpoint('xl') {
    box-sizing: border-box;
    max-width: 16.875rem;
  }

  &--only-name {
    width: 50%;
  }

  &--with-margin-top {
    margin-top: 5.438rem;
  }

  &--with-margin-left {
    margin-left: 2rem;
  }

  &--with-image {
    padding: 0;
    background-color: transparent;
    max-width: none;
    width: unset;

    @include media-breakpoint('xl') {
      width: 100%;
      max-width: 16.875rem;
    }

    .person__summary {
      position: absolute;
      left: 0;
      bottom: 0;
      height: calc(100% - 2rem);
      width: calc(100% - 1.25rem);
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 0 0 0.25rem 0.75rem;
      align-content: flex-end;
      z-index: 2;
      transition: bottom 300ms ease-in-out;

      @media (min-width: 385px) {
        width: calc(100% - 2.2rem);
      }

      @media (min-width: 390px) {
        width: calc(100% - 2.3rem);
      }

      @media (min-width: 397px) {
        width: calc(100% - 2.5rem);
      }

      @media (min-width: 405px) {
        width: calc(100% - 2.8rem);
      }

      @media (min-width: 414px) {
        width: calc(100% - 3rem);
      }

      @media (min-width: 420px) {
        width: calc(100% - 3.3rem);
      }
      @media (min-width: 425px) {
        width: calc(100% - 3.4rem);
      }

      @media (min-width: 429px) {
        width: calc(100% - 1.2rem);
      }

      @media (min-width: 466px) {
        margin: 0 0 0.5rem 1rem;
      }

      @include media-breakpoint('sm') {
        margin: 0.75rem 1rem;
        height: calc(100% - 2rem);
        width: calc(100% - 2rem);
      }
    }

    .person__image {
      display: flex;
      border-radius: 0.5rem;
      overflow: hidden;
      position: relative;

      > * {
        border-radius: 0.5rem;
      }
    }
  }
}
