@import '../variables';

.textarea {
  position: relative;
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 1.875rem;
  margin-bottom: 1.3125rem;
  transition: margin-bottom 300ms ease-in-out;

  &--error {
    .textarea__input--line {
      background: $accent;
    }
  }

  &__input {
    background: none;
    border: none;
    line-height: 1.75rem;
    padding: 0;
    color: $core-white;
    flex: 1;
    resize: vertical;
    max-height: 20rem;
    min-height: 5rem;

    &--line {
      width: 100%;
      height: 0.0625rem;
      background: $grey;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &:focus {
      outline: none;

      ~ .textarea__label {
        top: -0.875rem;
        font-size: 0.75rem;
        line-height: 0.875rem;
      }

      ~ .textarea__input--line {
        background: linear-gradient(22.45deg, #0e4ed7 3.28%, #f94743 102.02%);
      }
    }

    &--filled {
      ~ .textarea__label {
        top: -0.875rem;
        font-size: 0.75rem;
        line-height: 0.875rem;
      }
    }
  }

  &__input:-webkit-autofill {
    background: none;
    border: none;
    line-height: 1.75rem;
    padding: 0;
    color: $core-white;
    flex: 1;
    resize: vertical;
    max-height: 20rem;
    min-height: 5rem;

    &--line {
      width: 100%;
      height: 0.0625rem;
      background: $grey;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &:focus {
      outline: none;

      ~ .textarea__label {
        top: -0.875rem;
        font-size: 0.75rem;
        line-height: 0.875rem;
      }

      ~ .textarea__input--line {
        background: linear-gradient(22.45deg, #0e4ed7 3.28%, #f94743 102.02%);
      }
    }

    &--filled {
      ~ .textarea__label {
        top: -0.875rem;
        font-size: 0.75rem;
        line-height: 0.875rem;
      }
    }
  }

  &__label {
    font-size: 1rem;
    line-height: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    flex: 0 0 100%;
    color: $grey;
    transition: top 200ms ease-in-out, font-size 200ms ease-in-out,
      line-height 200ms ease-in-out;
    cursor: text;
  }
}
